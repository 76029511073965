import React, { useState, useEffect, useRef } from "react";
import * as d3 from "d3";
import { distributions } from "./distributions";
import { Link } from "react-router-dom";

const Distribution = ({ distribution, params }) => {
    const cdfBool = distribution.name.slice(-3) == "CDF" ? true : false
    const svgRef = useRef(null);
    const svgContainer = useRef(null);
    
    const [width, setWidth] = useState();
    const [height, setHeight] = useState();
  
    const func = distribution.func;
    const xLabelText = distribution.xLabel;
  
    let dimensions = {
      width: width,
      height: height,
      padding: 60
    }
  
    const chartWidth = dimensions.width - dimensions.padding;
    const chartHeight = dimensions.height - dimensions.padding;
  
    const getSvgContainerSize = () => {
      const newWidth = svgContainer.current.clientWidth;
      setWidth(newWidth);
  
      const newHeight = svgContainer.current.clientHeight;
      setHeight(newHeight);
    };
  
    useEffect(() => {
      // detect 'width' and 'height' on render
      getSvgContainerSize();
      // listen for resize changes, and detect dimensions again when they change
      window.addEventListener("resize", getSvgContainerSize);
      // cleanup event listener
      return () => window.removeEventListener("resize", getSvgContainerSize);
    }, []);
  
    useEffect(() => {
      const n = 10
      const data = Array.from({ length: n + 1 }, (_, i) => ({
        x: i,
        y: func(i, params),
      }));

    let xScale;
  
      if (cdfBool) {
        xScale = d3.scaleBand()
          .padding(-0.01)
          .domain(data.map(d => d.x.toString()))
          .range([dimensions.padding, chartWidth]);
      } else {
        xScale = d3.scaleBand()
          .padding(0.1)
          .domain(data.map(d => d.x.toString()))
          .range([dimensions.padding, chartWidth]);
      }
  
      const yScale = d3
        .scaleLinear()
        .domain([0, 1])
        .range([chartHeight, dimensions.padding]);
  
      const xAxis = d3.axisBottom(xScale).ticks(10).tickSizeOuter(0);
      const yAxis = d3.axisLeft(yScale);
  
      const svg = d3.select(svgRef.current);


      if (cdfBool) {
      svg.selectAll("rect").data(data).join("rect")
        .attr("x", (d) => xScale(d.x.toString())+xScale.bandwidth()/2)
        .attr("y", (d) => yScale(d.y))
        .attr("width", xScale.bandwidth())
        .attr("height", d => chartHeight - yScale(d.y))
        .attr("fill", "black");
      } else {
        svg.selectAll("rect").data(data).join("rect")
        .attr("x", (d) => xScale(d.x.toString()))
        .attr("y", (d) => yScale(d.y))
        .attr("width", xScale.bandwidth())
        .attr("height", d => chartHeight - yScale(d.y))
        .attr("fill", "black");
      }

    
    //   if (cdfBool) {
    //     svg.select(".x-axis")
    //       .attr("transform", `translate(${-xScale.bandwidth()/2}, ${chartHeight})`)
    //       .call(xAxis);
    //   } else {
        svg.select(".x-axis")
          .attr("transform", `translate(0, ${chartHeight})`)
          .call(xAxis);
    //   }
  
      svg.select(".x-axis")
        .selectAll(".tick")
        .select("text")
        .style("font-size", "15px")
  
      svg.selectAll(".x-label").remove()
  
      svg.append("text")
        .attr("class", "x-label")
        .attr("text-anchor", "middle")
        .attr("x", width/2)
        .attr("y", height - 15)
        .text(xLabelText)
        .style("font-size", "20px");
  
      svg.select(".y-axis")
        .attr("transform", `translate(${dimensions.padding}, 0)`)
        .call(yAxis);
  
      svg.select(".y-axis")
        .selectAll(".tick")
        .select("text")
        .style("font-size", "15px")
  
      svg.selectAll(".y-label").remove()
  
      svg.append("text")
        .attr("class", "y-label")
        .attr("text-anchor", "Middle")
        .attr("transform", `translate(${15}, ${height/2}) rotate(-90)`)
        // .attr("x", -width/4)
        // .attr("y", 15)
        // .attr("x", width/2)
        // .attr("y", height/2)
        .text("Probability")
        .style("font-size", "20px");

        if (cdfBool) {
        let additionalLength = xScale.bandwidth()/2; // Length to extend past the final tick
        svg.selectAll("line").remove()
        svg.append("line")
            .attr("x1", xScale.range()[1]) // Start of the line at the end of your axis
            .attr("y1", chartHeight)
            .attr("x2", xScale.range()[1] + additionalLength) // Extend line by additional length
            .attr("y2", chartHeight)
            .style("stroke", "black") // Apply styles as needed
            .style("stroke-width", 1);
        }
  
    }, [params, func, width, height]);
  
    return (
      <div className="h-100" ref={svgContainer}>
        <svg ref={svgRef} width={width} height={height} style={{font: "25px"}}>
          <g className="x-axis" />
          <g className="y-axis" />
        </svg>
      </div>
    );
};


const IndividualDistribution = ({selectedDist}) => {
    const initialParams = distributions[selectedDist].params.reduce(
      (acc, param) => ({ ...acc, [param.name]: param.default }),
      {}
    );
    const [params, setParams] = useState(initialParams)
  
    useEffect(() => {
      const defaultParams = distributions[selectedDist].params.reduce(
        (acc, param) => ({ ...acc, [param.name]: param.default }),
        {}
      );
      setParams(defaultParams);
    }, [selectedDist]);
  
    const handleParamChange = (event) => {
      setParams({ ...params, [event.target.name]: parseFloat(event.target.value) });
    };
  
    const selectedDistribution = distributions[selectedDist];
    
    return (
      <div className="h-100">
        <Distribution key={selectedDist} distribution={selectedDistribution} params={params} />
  
        <div className="container">
          {selectedDistribution.params.map((param) => (
            <div key={param.name} className="row py-2">
              <div className="col-4">
                <label className="form-label h5" htmlFor={`${param.name}-input`}>{param.label}: {params[param.name]}
                </label>
              </div>
              <div className="col-8">
                <input
                  key={`${selectedDist}-${param.name}`}
                  id={`${param.name}-input`}
                  type="range"
                  className="form-range"
                  name={param.name}
                  min={param.min}
                  max={param.max}
                  step={param.step}
                  value={params[param.name]}
                  onChange={handleParamChange}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    );
};

const DoubleDistribution = ({ selectedDist }) => {
    const initialParams = distributions[selectedDist].params.reduce(
        (acc, param) => ({ ...acc, [param.name]: param.default }),
        {}
      );
      const [params, setParams] = useState(initialParams)
    
      useEffect(() => {
        const defaultParams = distributions[selectedDist].params.reduce(
          (acc, param) => ({ ...acc, [param.name]: param.default }),
          {}
        );
        setParams(defaultParams);
      }, [selectedDist]);
    
      const handleParamChange = (event) => {
        setParams({ ...params, [event.target.name]: parseFloat(event.target.value) });
      };
    
      const selectedDistribution = distributions[selectedDist];
      
      return (
        <div className="h-100">
            <div className="container">
                <div className="row flex">
                    <div className="ratio ratio-1x1 w-50 d-md-block d-none">
                        <Distribution key={selectedDist} distribution={distributions[selectedDist]} params={params} />
                    </div>
                    <div className="ratio ratio-1x1 w-50 d-md-block d-none">
                        <Distribution key={selectedDist} distribution={distributions[selectedDist.concat("CDF")]} params={params} />
                    </div>
                    <div className="ratio ratio-1x1 w-100 d-block d-md-none">
                        <Distribution key={selectedDist} distribution={distributions[selectedDist]} params={params} />
                    </div>
                    <div className="ratio ratio-1x1 w-100 d-block d-md-none">
                        <Distribution key={selectedDist} distribution={distributions[selectedDist.concat("CDF")]} params={params} />
                    </div>
                </div>
            </div>
    
          <div className="container">
            {selectedDistribution.params.map((param) => (
              <div key={param.name} className="row py-2">
                <div className="col-4">
                  <label className="form-label h5" htmlFor={`${param.name}-input`}>{param.label}: {params[param.name]}
                  </label>
                </div>
                <div className="col-8">
                  <input
                    key={`${selectedDist}-${param.name}`}
                    id={`${param.name}-input`}
                    type="range"
                    className="form-range"
                    name={param.name}
                    min={param.min}
                    max={param.max}
                    step={param.step}
                    value={params[param.name]}
                    onChange={handleParamChange}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      );
  };

export default Distribution
export {
    IndividualDistribution,
    DoubleDistribution
}
import React from "react";
import {Outlet} from "react-router-dom";
import Navbar from "../Navbar";

const Layout = () => {
  return (
    // <div className="d-flex w-100">
    //   <Navbar />
    //   <Outlet />
    // </div>
    <div class="container-fluid h-100">
        <div class="row h-100">
          <Navbar />
        
            <main role="main" class="ml-sm-auto col-lg-10 px-4">
                <Outlet />
            </main>
        </div>
    </div>
  );
};

export default Layout;
// App.js
import React, { useState, useEffect, useRef } from "react";
import * as d3 from "d3";
import { distributions } from "./distributions"
import { Link } from "react-router-dom"
import Distribution from './DiscreteDistribution';

// const Distribution = ({ distribution, params }) => {
//   const svgRef = useRef(null);
//   const svgContainer = useRef(null);
  
//   const [width, setWidth] = useState();
//   const [height, setHeight] = useState();

//   const func = distribution.func;
//   const xLabelText = distribution.xLabel;

//   let dimensions = {
//     width: width,
//     height: height,
//     padding: 60
//   }

//   const chartWidth = dimensions.width - dimensions.padding;
//   const chartHeight = dimensions.height - dimensions.padding;

//   const getSvgContainerSize = () => {
//     const newWidth = svgContainer.current.clientWidth;
//     setWidth(newWidth);

//     const newHeight = svgContainer.current.clientHeight;
//     setHeight(newHeight);
//   };

//   useEffect(() => {
//     // detect 'width' and 'height' on render
//     getSvgContainerSize();
//     // listen for resize changes, and detect dimensions again when they change
//     window.addEventListener("resize", getSvgContainerSize);
//     // cleanup event listener
//     return () => window.removeEventListener("resize", getSvgContainerSize);
//   }, []);

//   useEffect(() => {
//     const n = 10
//     const data = Array.from({ length: n + 1 }, (_, i) => ({
//       x: i,
//       y: func(i, params),
//     }));

//     const xScale = d3.scaleBand()
//       .padding(0.1)
//       .domain(data.map(d => d.x.toString()))
//       .range([dimensions.padding, chartWidth]);

//     const yScale = d3
//       .scaleLinear()
//       .domain([0, 1])
//       .range([chartHeight, dimensions.padding]);

//     const xAxis = d3.axisBottom(xScale).ticks(10);
//     const yAxis = d3.axisLeft(yScale);

//     const svg = d3.select(svgRef.current);

//     svg.selectAll("rect").data(data).join("rect")
//       .attr("x", (d) => xScale(d.x.toString()))
//       .attr("y", (d) => yScale(d.y))
//       .attr("width", xScale.bandwidth())
//       .attr("height", d => chartHeight - yScale(d.y))
//       .attr("fill", "black");

//     svg.select(".x-axis")
//       .attr("transform", `translate(0, ${chartHeight})`)
//       .call(xAxis);

//     svg.select(".x-axis")
//       .selectAll(".tick")
//       .select("text")
//       .style("font-size", "15px")

//     svg.selectAll(".x-label").remove()

//     svg.append("text")
//       .attr("class", "x-label")
//       .attr("text-anchor", "middle")
//       .attr("x", width/2)
//       .attr("y", height - 15)
//       .text(xLabelText)
//       .style("font-size", "20px");

//     svg.select(".y-axis")
//       .attr("transform", `translate(${dimensions.padding}, 0)`)
//       .call(yAxis);

//     svg.select(".y-axis")
//       .selectAll(".tick")
//       .select("text")
//       .style("font-size", "15px")

//     svg.selectAll(".y-label").remove()

//     svg.append("text")
//       .attr("class", "y-label")
//       .attr("text-anchor", "Middle")
//       .attr("transform", `rotate(-90)`)
//       .attr("x", -width/4)
//       .attr("y", 15)
//       .text("Probability")
//       .style("font-size", "20px");

//   }, [params, func, width, height]);

//   return (
//     <div className="h-100" ref={svgContainer}>
//       <svg ref={svgRef} width={width} height={height} style={{font: "25px"}}>
//         <g className="x-axis" />
//         <g className="y-axis" />
//       </svg>
//     </div>
//   );
// };


const CustomChart = () => {
  const [selectedDist, setSelectedDist] = useState("binomial");
  const initialParams = distributions[selectedDist].params.reduce(
    (acc, param) => ({ ...acc, [param.name]: param.default }),
    {}
  );
  const [params, setParams] = useState(initialParams)

  useEffect(() => {
    const defaultParams = distributions[selectedDist].params.reduce(
      (acc, param) => ({ ...acc, [param.name]: param.default }),
      {}
    );
    setParams(defaultParams);
  }, [selectedDist]);

  const handleDistChange = (event) => {
    setSelectedDist(event.target.value);
  };

  const handleParamChange = (event) => {
    setParams({ ...params, [event.target.name]: parseFloat(event.target.value) });
  };

  const selectedDistribution = distributions[selectedDist];
  
  return (
    <div className="h-100">
      <Distribution key={selectedDist} distribution={selectedDistribution} params={params} />

      <div className="container">
        <div className="row py-1">
          <div className="col-4">
            <label className="form-label h5" htmlFor="distribution-select">Distribution: </label>
          </div>
          <div className="col-8">
            <select className="form-select" id="distribution-select" value={selectedDist} onChange={handleDistChange}>
              {Object.entries(distributions).map(([key, dist]) => (
                <option key={key} value={key}>
                  {dist.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        {selectedDistribution.params.map((param) => (
          <div key={param.name} className="row py-2">
            <div className="col-4">
              <label className="form-label h5" htmlFor={`${param.name}-input`}>{param.label}: {params[param.name]}
              </label>
            </div>
            <div className="col-8">
              <input
                key={`${selectedDist}-${param.name}`}
                id={`${param.name}-input`}
                type="range"
                className="form-range"
                name={param.name}
                min={param.min}
                max={param.max}
                step={param.step}
                value={params[param.name]}
                onChange={handleParamChange}
              />
            </div>
          </div>
        ))}
        <div className="row py-2">
          <p>Learn more about the <Link to="/about">{selectedDistribution.name} distribution</Link></p>
        </div>
      </div>
    </div>
  );
};

export default CustomChart;

import React from "react";
import { BrowserRouter, Route, Link } from "react-router-dom";
import Accordion from 'react-bootstrap/Accordion';

function Navbar() {
  return (
  <>
  <nav class="d-lg-none d-block bg-light">
    <button class="btn btn-primary d-lg-none" type="button" data-toggle="collapse" data-target="#sidebar" aria-controls="sidebar" aria-expanded="false" aria-label="Toggle navigation">
        Toggle navigation
    </button>
  </nav>

  <nav class="col-lg-2 d-none d-lg-block bg-black sidebar">
      <div class="sidebar-sticky">
          <ul class="nav flex-column">
              <li class="nav-item">
                <Link to="/" className="nav-link">Probability Distribution Explorer</Link>
              </li>
              <li class="nav-item">
                <Link to="/about" className="nav-link">About</Link>
              </li>
              <li class="nav-item">
                <Accordion defaultActiveKey="" className="accordion-flush">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Background</Accordion.Header>
                    <Accordion.Body className="bg-black">
                      <ul class="nav flex-column">
                        <li class="nav-item">
                          <Link to="/" className="nav-link">Home</Link>
                        </li>
                        <li class="nav-item">
                          <Link to="/about" className="nav-link">About</Link>
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>Distributions</Accordion.Header>
                    <Accordion.Body className="bg-black">
                      <ul class="nav flex-column">
                        <li class="nav-item">
                          <Link to="/discrete-distributions/Bernoulli" className="nav-link">Bernoulli</Link>
                        </li>
                        <li class="nav-item">
                          <Link to="/discrete-distributions/Binomial" className="nav-link">Binomial</Link>
                        </li>
                        <li class="nav-item">
                          <Link to="/discrete-distributions/Geometric" className="nav-link">Geometric</Link>
                        </li>
                        <li class="nav-item">
                          <Link to="/discrete-distributions/Poisson" className="nav-link">Poisson</Link>
                        </li>
                        <li class="nav-item">
                          <Link to="/discrete-distributions/Hypergeometric" className="nav-link">Hypergeometric</Link>
                        </li>
                        <li class="nav-item">
                          <Link to="/discrete-distributions/Uniform" className="nav-link">Uniform</Link>
                        </li>
                        <li class="nav-item">
                          <Link to="/discrete-distributions/NegativeBinomial" className="nav-link">Negative Binomial</Link>
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </li>
          </ul>
      </div>
  </nav>
  </>
  );
}

export default Navbar;
import React from 'react';
import { DoubleDistribution } from '../DiscreteDistribution';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function BinomialPage() {
    return (
        <div className="section w-100">
            <div className="header d-flex justify-content-center">
                <h1 className="display-4">Binomial Distribution</h1>
            </div>
            <Container>
                <Row >
                    <Col className="d-none d-lg-block col-2"></Col>
                    <Col>
                        <DoubleDistribution selectedDist={"binomial"}/>
                    </Col>
                    <Col className="d-none d-lg-block col-2"></Col>
                </Row>
            </Container>
        </div>
    );
}

export default BinomialPage
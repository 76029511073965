import React from 'react';
import CustomChart from "../App"
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Home() {
    return (
        <div className="section w-100">
            <div className="header d-flex justify-content-center">
                <h1 className="display-4">Probability Distribution Explorer</h1>
            </div>
            <Container>
                <Row >
                    <Col className="d-none d-lg-block col-2"></Col>
                    <Col className="ratio ratio-16x9 d-none d-md-block">
                        <CustomChart />
                    </Col>
                    <Col className="ratio ratio-4x3 d-lg-none d-block">
                        <CustomChart />
                    </Col>
                    <Col className="d-none d-lg-block col-2"></Col>
                </Row>
            </Container>
        </div>
    );
}

export default Home;